import React from "react";

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome/index.d"

// Fix for rendering in Content Studio Editmode and CLS on page load 
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

export default function FontAwesomeIconWrapper(props : FontAwesomeIconProps) {
  return (
    <FontAwesomeIcon {...props} /> 
  )
}
